jQuery(document).ready(function($) {
	$('.page-gallery .slick').slick({
		dots: false,
		arrows: false,
		draggable: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000
	});
	$('.page-mobile-gallery .slick').slick({
		dots: false,
		arrows: true
	});

	$('.page-about .number > span > span').counterUp({
		delay: 10,
		time: 1000
	});

	if( $('.page-gmap').length>0 ){
		$.getScript('https://maps.googleapis.com/maps/api/js?key=' + gMapsApiKey, function( data, textStatus, jqxhr ){
			$('.page-gmap').each(function(index){
				var map,
					marker,
					draggableOpt = true,
					$this = $(this),
					lat = $this.data('lat'),
    				lng = $this.data('lng'),
    				zoom = $this.data('zoom'),
    				markerPng = $this.data('marker-png'),
    				markerSvg = $this.data('marker-svg');

    				var is_internetExplorer11= navigator.userAgent.toLowerCase().indexOf('trident') > -1;
					var marker_url = ( is_internetExplorer11 ) ? markerPng : markerSvg;

    				$this.append('<div id="map_' + index + '"></div>');

    				if( Modernizr.touch ){
						draggableOpt = true;
					}

					var myLatLng = new google.maps.LatLng(lat, lng);
					map = new google.maps.Map(document.getElementById('map_' + index), {
						zoom: zoom,
						center: {
							lat: lat,
							lng: lng
						},
						draggable: true,
						scrollwheel: false,
						zoomControlOptions: {
							style: google.maps.ZoomControlStyle.SMALL,
							position: google.maps.ControlPosition.LEFT_TOP
						},
						streetViewControlOptions: {
							position: google.maps.ControlPosition.LEFT_TOP
						},
						styles: [{"featureType":"all","elementType":"all","stylers":[{"invert_lightness":false},{"hue":"#0a1f00"}]}]
					});

					marker = new google.maps.Marker({
						position: myLatLng,
						map: map,
						icon: marker_url
					});
			});
		});
	}
});
