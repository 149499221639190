jQuery(document).ready(function($) {
	$('.macrocategory-image .slick').slick({
		dots: false,
		arrows: false,
		draggable: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000
	});
});
