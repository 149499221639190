jQuery(document).ready(function($) {
	var topCarousel = $('.top-carousel .slick');

	topCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
		if( isBreakpoint('lg') ){
			var curSlide = $(slick.$slides[ currentSlide ]);
			if( curSlide.find('.embed-responsive > iframe').length==1 ){
				var videoFrameId = curSlide.find('.embed-responsive > iframe').attr('id');
				yt_players[videoFrameId].stopVideo();
			}
		}
	});
	topCarousel.on('afterChange', function(event, slick, currentSlide, nextSlide){
		if( isBreakpoint('lg') ){
			var curSlide = $(slick.$slides[ currentSlide ]);
			if( curSlide.find('.embed-responsive > iframe').length==1 ){
				var videoFrameId = curSlide.find('.embed-responsive > iframe').attr('id');
				yt_players[videoFrameId].playVideo();
			}
		}
	});

	topCarousel.slick({
		dots: true,
		nav: false,
		arrows: false,
		adaptiveHeight: true
	});

	if( isBreakpoint('xs') ){
		$('.extract-about .slick').slick({
			dots: true,
			nav: false,
			arrows: false,
			adaptiveHeight: true
		});
	}
	else{
		var aboutMaxHeight = Math.max.apply(null, $('.extract-about .item .image').map(function(){ return $(this).height(); }).get());
		$('.extract-about .item .image > span').height( aboutMaxHeight );
		$('.extract-about .item .text > div').height( aboutMaxHeight );
		$('.extract-about .item .text > div > div').addClass( 'absolute' );
	}

	$(window).on('breakpointChanged', function(){
		$('.extract-about .item .image > span').height( '' );
		$('.extract-about .item .text > div').height( '' );
		$('.extract-about .item .text > div > div').removeClass( 'absolute' );

		var aboutMaxHeight = Math.max.apply(null, $('.extract-about .item .image').map(function(){ return $(this).height(); }).get());
		$('.extract-about .item .image > span').height( aboutMaxHeight );
		$('.extract-about .item .text > div').height( aboutMaxHeight );
		$('.extract-about .item .text > div > div').addClass( 'absolute' );
	});
});
