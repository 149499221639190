function fixSameHeightRows(){
	var $sameHeightRow = jQuery('.row > .row-height .kd-full-height');

	$sameHeightRow.each(function(){
		var applyOn = '',
			breakpointQuery = '';

		if( jQuery(this).data('fullheight') ){
			applyOn = jQuery(this).data('fullheight').split(",");
		}

		if( applyOn.length ){
			for (var i = 0; i < applyOn.length; i++) {
				if( i!=0 ){
					breakpointQuery += ', ';
				}

				breakpointQuery += '.device-' + applyOn[i];
			}
		}

		if( breakpointQuery!='' ){
			if( jQuery(breakpointQuery).is(':visible') ){
				jQuery(this).css({ height: '' });
				jQuery(this).css({ height: parseInt( jQuery(this).closest('.row').height() ) });
			}
		}
	});
}

jQuery(document).ready(function() {
	fixSameHeightRows();
});

jQuery(window).load(function() {
	fixSameHeightRows();
});

jQuery(window).resize(function() {
	fixSameHeightRows();
});
