// Hide Header on on scroll down
var didScroll;
var lastScrollTop = 0;
var delta = 5;
var element = jQuery('.header .navbar-default');
var fixedClass = 'fixed';
var navbarHeight = jQuery('.primary-nav').position().top;

function hasScrolled() {
	var st = jQuery(this).scrollTop();
	
	// Make sure they scroll more than delta
	if(Math.abs(lastScrollTop - st) <= delta){
		return;
	}
	
	// If they scrolled down and are past the navbar, add class .nav-up.
	// This is necessary so you never see what is "behind" the navbar.
	if (st > lastScrollTop && st > navbarHeight){
		// Scroll Down
		element.removeClass('nav-down').addClass('nav-up');
		element.parent().removeClass('nav-up').addClass('nav-down');
		jQuery('.navbar-collapse.collapse.in').removeClass('in');

		jQuery('.navbar-search').slideUp().removeClass('open');
	} else {
		// Scroll Up
		if(st + jQuery(window).height() < jQuery(document).height()) {
			element.removeClass('nav-up').addClass('nav-down');
			
			if( st < element.height() ){
				element.removeClass('nav-up').removeClass('nav-down');
				element.parent().removeClass('nav-down').addClass('nav-up');
			}
		}
	}
	
	lastScrollTop = st;
}

function scrollTo( element ){
	var offset = jQuery( element.attr('href') ).offset().top;

	if ( isBreakpoint('xs') || isBreakpoint('sm') ){
		jQuery('.navbar-collapse').removeClass('in');
	}

	jQuery('html, body').animate({
		scrollTop: offset
	}, 500);

	history.pushState({}, '', element.attr("href"));
}

jQuery(window).scroll(function(event){
	didScroll = true;

	if( jQuery(window).scrollTop() > element.height() ){
		if( !element.hasClass( fixedClass ) ){
			element.addClass( fixedClass );
		}
	}
	else{
		element.removeClass( fixedClass );
	}
});

setInterval(function() {
	if (didScroll) {
		hasScrolled();
		didScroll = false;
	}
}, 250);



jQuery(document).ready(function() {
	jQuery('.scrollto').click(function(e) {
		e.preventDefault();
		scrollTo( jQuery(this) );
	});
	jQuery('.primary-nav > li > a').click(function(e) {
		if( jQuery(this).attr("href").charAt(0)==='#' ){
			e.preventDefault();
			scrollTo( jQuery(this) );
		}
	});
});