jQuery(document).ready(function($) {
	$('.product-gallery .slick').slick({
		dots: false,
		arrows: true
	});
	
	$('.product-photos .slick').slick({
		dots: false,
		arrows: false,
		draggable: false,
		fade: true,
		autoplay: true,
		autoplaySpeed: 4000
	});

	$('.product-video .yt-hd-thumbnail').youTubeHDThumbnail({
		darkenThumbnail: true
	});

	$('.product-tabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
		var target = $(e.target).attr("href"),
			background = $(target).data('background');

		if( background ){
			$('.product-tabs').addClass('hasBackground');

			if( isBreakpoint('md') || isBreakpoint('lg') ){
				$('.product-tabs > div').css('background-image', 'url(' + background) + ')';
			}
		}
		else{
			$('.product-tabs').removeClass('hasBackground');

			if( isBreakpoint('md') || isBreakpoint('lg') ){
				$('.product-tabs > div').css('background-image', '');
			}
		}

		if( $(target).find('.product-plus .slick').length!=0 ){
			if( !$(target).find('.product-plus .slick-slider').length==0 ){
				$(target).find('.product-plus .slick').slick('unslick');
			}

			$(target).find('.product-plus .slick').slick({
				dots: false,
				arrows: false,
				draggable: false,
				fade: true,
				autoplay: true,
				autoplaySpeed: 4000
			});
		}
	});

	$('.product-links .open-tabs').click(function(e){
		e.preventDefault();

		var tabs = $('.product-tabs'),
			$this = $(this);

		if( !$this.hasClass('video') ){
			$('.product-links .open-tabs .closed').addClass('hidden');
			$('.product-links .open-tabs .opened').removeClass('hidden');
		}

		if( tabs.hasClass('open') ){
			$('.product-links .open-tabs .closed').removeClass('hidden');
			$('.product-links .open-tabs .opened').addClass('hidden');

			tabs.slideUp(500, function(){
				tabs.removeClass('open');
			});
		}
		else{
			if( $this.hasClass('video') ){
				$('.nav-tabs a[href="#' + tabs.find('.tab-content > div.video').eq(0).attr('id') + '"]').tab('show');
			}
			else{
				$('.nav-tabs a[href="#' + tabs.find('.tab-content > div').eq(0).attr('id') + '"]').tab('show');
			}

			tabs.slideDown(500, function(){
				tabs.addClass('open');
			});
		}
	});

	$('.description-toggle').click(function(e){
		e.preventDefault();

		if( !$(this).hasClass('open') ){
			$(this).addClass('open')
			$('.extended-description').slideDown();
		}
		else{
			$(this).removeClass('open')
			$('.extended-description').slideUp();
		}
	});

	$('.tab-mobile-nav .prev > a, .tab-mobile-nav .next > a').click(function(){
		$('.product-tabs .nav-tabs > li.active').removeClass('active');
		$('.product-tabs .nav-tabs > li > a[href="' + $(this).attr('href') + '"]').parent().addClass('active');
	});
});
